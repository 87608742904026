@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    overflow-x: hidden;
}

@layer components {
    .heroButton{
        @apply px-6 py-2 border border-[#ffffff] rounded-full uppercase text-xs tracking-widest text-white transition-all hover:border-[#7479ee] hover:text-[#7479ee] mb-2
    }

    .contactInput {
        @apply outline-none bg-gray-900 rounded-sm border-b px-6 py-4 border-[#242424] text-gray-500 placeholder-gray-500 transition-all focus:border-[#7479ee]/40 hover:border-[#7479ee]/40
    }
}